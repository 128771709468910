@import "./variables.sass"

.CanvasWrapper
  .Members, .Invite
    flex: 1
    margin-top: 0px
    border-top: 1px solid $lightLine
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: 16px

  .Members-List, .Invites-List
    max-width: 640px
    min-width: 320px
    width: 100%
    margin-bottom: 64px

    h2
      padding-bottom: 4px
      margin-bottom: 12px
      border-bottom: 1px solid $lightLine

    .Member, .Invitation
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 8px
      line-height: 32px

      .Email
        flex: 1

      .MuiIconButton-root
        margin-left: 4px

  .Invite-Input
    display: flex
    margin-top: 24px

    .MuiButton-root
      margin-left: 8px
      width: 100px

    .MuiInputLabel-outlined
      transform: translate(14px, 14px) scale(1)

      &.MuiInputLabel-shrink
        transform: translate(14px, -6px) scale(0.75)

    .MuiOutlinedInput-input
      padding: 12px 14px

  .Invite
    text-align: center
    margin-bottom: 96px

    h2
      margin-bottom: 24px

    .MuiButton-contained
      margin: 0px 16px

.CanvasWrapper.Dark
  .Members, .Invite
    border-top: 1px solid $darkLine

    .Members-List, .Invites-List
      h2
        border-bottom: 1px solid $darkLine
