@import "./variables.sass"

h1, h2, h3, h4
  margin: 0

.CanvasWrapper, .JournalWrapper
  background: $lightBg
  min-height: 100vh
  display: flex
  flex-direction: column
  background-size: cover

  .CanvasMenu
    display: flex
    justify-content: center
    align-items: center
    padding: 4px 6px 4px 8px
    background: #f4f4f8

    .CanvasControls
      button
        margin-left: 8px

    .CanvasTitle
      flex: 1
      display: flex
      align-items: center

    h1
      font-size: 20px

    .logo
      display: none
      margin-left: 6px

    .NewBoard
      margin-left: 4px

      span
        font-size: 14px
        line-height: 25px

    .MuiButton-text
      padding: 3px 8px

  .CenterControls
    display: flex
    align-items: center

  .CanvasControls
    display: flex
    // flex: 1
    justify-content: flex-end
    align-items: center
    flex: 1

  .MuiIconButton-label
    font-size: 22px

    .MuiSvgIcon-root
      font-size: 22px

  .MuiFilledInput-root
    background: none
    border-radius: 4px

  .TodayButton.MuiButton-contained
    background: rgba(0, 0, 0, 0.09)
    margin-left: 4px

  .MuiFormControl-root
    margin: 0
    margin-left: 4px

    .MuiFilledInput-input
      background: none
      border-radius: 4px
      padding: 7px 4px
      cursor: pointer
      text-align: center
      width: 96px
      font-size: 14px
      font-weight: 500
      vertical-align: middle
      line-height: 30px
      // text-transform: uppercase

    .MuiFilledInput-underline:before
      content: none

    .MuiFilledInput-underline:after
      border: 0

.ActiveDate
  .MuiIconButton-label
    position: relative

    // text-decoration: underline
    &::before
      transform: translateX(-50%)
      border-radius: 100%
      position: absolute
      background: #bbb
      bottom: -2px
      height: 4px
      content: ''
      width: 4px
      left: 50%

.Canvas
  flex: 1
  display: flex
  margin: 0px 0px 0px
  padding: 0px
  overflow: hidden
  border-top: 1px solid $lightLine

  .Columns
    overflow-x: auto
    display: flex
    flex: 1
    // align-items: flex-start

  .Column
    display: flex
    flex-direction: column
    margin: 0px
    // border-radius: 4px
    border-right: 1px solid $lightLine
    min-width: 200px
    // max-width: 360px
    flex: 1
    text-align: left
    overflow: hidden

    &:first-of-type
      .Prompts, .ColumnTitle
        margin-left: 2px

    &:last-of-type
      border-right: none
      .Prompts, .ColumnTitle
        margin-right: 4px

    .ColumnTitle
      display: flex
      align-items: center
      margin-bottom: 4px

      .Line
        display: none
        flex: 1
        background: black
        height: 4px
        margin: 6px 6px 0px
        opacity: 0.2

      h2
        color: white
        text-transform: uppercase
        margin: 8px 8px 4px
        border-radius: 4px
        padding: 4px 12px

    .Prompts
      display: flex
      flex: 1
      flex-direction: column
      padding: 0px 4px

    h1
      font-size: 18px

    h2
      font-size: 12px
      font-weight: 600
      letter-spacing: 0.16666em

    h3
      // font-family: garamond-premier-pro, sans-serif
      font-size: 14px
      margin-top: 4px
      margin-bottom: 4px
      font-weight: 600
      display: flex
      align-items: flex-start
      justify-content: space-between

      .iconify
        margin-left: 8px
        font-size: 18px
        flex-basis: 20px
        flex-shrink: 0

      .Pin
        color: #fb6a6a

.Canvas, .Fullscreen, .Edit
  overflow: auto

.Canvas, .Fullscreen, .Edit, .CanvasMenu
  textarea
    padding: 2px 6px
    border: 0
    overflow: auto
    outline: none
    -webkit-box-shadow: none
    -moz-box-shadow: none
    box-shadow: none
    resize: none
    flex: 1
    line-height: 1.25em
    width: 100%
    align-self: center
    // border-radius: 4px
    // background-color: rgba(255, 255, 255, 0)
    transition: background 0.75s
    font-weight: 500
    font-size: 15px
    background-color: rgba(0, 0, 0, 0)
    color: black

    &:focus-visible
      // background-color: rgba(255, 255, 255, 1)
      transition: background-color 0.75s

.Canvas, .Fullscreen
  .Prompt
    // background: rgba(255, 255, 255, 0.1)
    // padding: 8px
    margin: 0px 0px 16px
    // border-radius: 4px
    flex: 1
    display: flex
    flex-direction: column
    min-height: 7em
    position: relative

    .Shimmer
      height: 1.1em
      background: rgba(200, 200, 200, 0.5)
      margin: 8px 8px 0px

    h3
      // font-family: garamond-premier-pro, sans-serif
      font-size: 15px
      margin-left: 6px
      margin-right: 6px
      margin-bottom: 4px
      padding-bottom: 0px
      font-weight: 600
      line-height: 1.1em
      color: black
      opacity: 0.8

    textarea
      transition: opacity 0.75s

      &:focus-visible
        transition: opacity 0.75s

    .Stats
      font-size: 12px
      position: absolute
      right: 16px
      bottom: -12px
      font-weight: 600
      opacity: 0
      transition: opacity 0.5s
      display: flex

      span
        line-height: 24px
        vertical-align: middle
        display: flex
        align-items: center
        flex-direction: row
        margin-left: 12px

      img
        height: 20px
        margin-right: 4px

    textarea:focus-visible + .Stats
      opacity: 1
      transition: opacity 0.5s

.Canvas
  .Prompt
    h3
      color: #222

.Fullscreen
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  background: rgba(235, 235, 240, 1)
  align-items: center
  justify-content: center

  .Prompt
    position: relative
    margin: 24px auto
    background: $lightBg
    border-radius: 4px
    max-width: 640px
    display: block
    padding: 24px
    padding-bottom: 30vh
    box-shadow: 0px 24px 24px -20px rgba(50, 50, 50, 0.2), 0px 4px 12px -6px rgba(50, 50, 50, 0.1)

    h3
      // font-family: garamond-premier-pro, sans-serif
      font-size: 20px
      margin: 0px 0px 24px

    textarea
      font-size: 18px
      min-height: 50vh
      padding: 0px

    .Stats
      position: fixed
      right: 36px
      bottom: 32px
      opacity: 1

@media (max-width: $singleColumnWidth)
  html, #root, body
    height: unset
    // overflow: unset

  .CanvasWrapper
    height: unset

    .NewBoard, .ProgressWrapper
      display: none

    .CanvasControls
      & > div
        display: none

        &.BoardSelect
          display: block

    .ActiveDate
      .MuiIconButton-label
        &::before
          background: white

    .Canvas
      overflow: unset

      .Columns
        flex-direction: column
        overflow: unset

        .Column
          min-width: auto
          height: unset
          overflow: unset
          border-right: none
          margin: 0px
          display: block
          flex: 0

          .Prompts
            height: unset

.Dark.CanvasWrapper, .Dark.JournalWrapper
  background: $darkBg

  .CanvasMenu
    background: #2c2e34

  .TodayButton.MuiButton-contained
    background: rgba(255, 255, 255, 0.09)
    color: white

  .Canvas, .Fullscreen, .Journal, .Edit
    border-top: 1px solid $darkLine

    textarea
      color: white

  .Fullscreen
    background: #1e1e22

    .Prompt
      background-color: $darkBg
      color: white
      box-shadow: 0px 24px 24px -20px rgba(0, 0, 0, 0.6), 0px 4px 12px -6px rgba(0, 0, 0, 0.2)

  .Canvas
    .Column
      border-right-color: $darkLine

      h2
        color: black

    .Prompt
      h3
        color: white

      .Shimmer
        background: rgba(50, 50, 50, 0.5)

    .ColumnTitle
      .Line
        background: white
        opacity: 0.2

// modern theme - modifier
.ModernTheme.CanvasWrapper, .ModernTheme.JournalWrapper
  background: #f4f4f4

  .Canvas
    border-top-color: #e4e4e4

    .Pin
      color: #444

    .ColumnTitle
      h2
        text-transform: none
        font-size: 16px
        letter-spacing: 0
        font-weight: 800
        color: #1f2333 !important
        opacity: 1 !important

      .Line
        display: none

    .Column
      border-right-color: #ddd

    .Prompt
      h3
        font-family: $system-font-family
        font-size: 15px
        font-weight: 600
        color: #222 !important

// disable scroll - modifier
.CanvasWrapper
  .Canvas
    .Columns.DisableScroll
      .Column
        flex: 0
        min-width: 360px

        .Prompts
          display: block

          .Prompt
            display: block
            min-height: unset
