@import "./variables.sass"

html
  width: 100%
  height: 100%

body
  margin: 0
  font-family: $font-family
  font-weight: 500
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  width: 100%
  height: 100%
  overflow-x: hidden
  line-height: 1.1em

textarea
  font-family: $font-family
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

#root
  height: 100%

.logo
  display: none
  height: 20px

.BoardSelect
  &.MuiFilledInput-root
    margin-left: 8px
    border-radius: 4px
    font-weight: 500
    background: none

    &:hover
      &:before
        border: none

    &:before
      border: none

    &:after
      border: none

    .MuiSelect-root
      padding: 6px 32px 6px 8px
      font-size: 14px

.MuiMenu-paper
  .MuiListItem-root
    font-size: 14px

.ProgressLabel
  font-family: garamond-premier-pro, sans-serif
  font-style: italic
  font-weight: 400

.BoardSettings
  .MuiListItemIcon-root
    min-width: 36px

.CenterWrapper
  display: flex
  max-width: 640px
  flex-direction: column
  justify-content: center
  align-items: center
  flex: 1

.RoleSelect
  width: 100px

  .MuiSelect-root
    padding: 8px 16px
