.ProgressWrapper
  display: flex
  align-items: center
  font-size: 14px
  font-weight: 600

  .Progress
    margin: 0px 16px
    position: relative
    width: 120px
    height: 6px
    border-radius: 20px
    background: rgba(0, 0, 0, 0.1)
    overflow: hidden

    .Bar
      background: #88B688
      position: absolute
      top: 0
      left: 0
      height: 100%

.Dark
  .ProgressWrapper
    .Progress
      background: rgba(255, 255, 255, 0.2)
