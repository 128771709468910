.SignIn
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100vh

  img
    cursor: pointer
    height: 46px
