@import "./variables.sass"

.Edit
  display: flex
  overflow-y: hidden
  overflow-x: auto
  flex: 1
  margin: 0px 0px 0px
  border-top: 1px solid $lightLine

  .Column
    border-right: 1px solid $lightLine
    display: flex
    flex-direction: column
    min-width: 180px
    max-width: 360px
    flex: 1
    text-align: left
    overflow: hidden

    .LimitNotice
      padding: 4px 8px 0px 12px
      opacity: 0.3

    .Droppable
      min-height: 16px

    .Prompts
      min-height: 12px
      overflow-y: auto

      .Prompt
        display: flex
        padding: 2px 8px 2px 4px
        align-items: center

        .DragPrompt.MuiSvgIcon-root
          align-self: flex-start
          margin-top: 4px
          margin-left: 2px
          opacity: 0.4

        .ToggleAutoRenew
          margin-left: 4px

          .Off
            opacity: 0.4

  textarea
    // flex: unset

  .Column-Actions
    display: flex
    padding: 8px 6px 2px
    align-items: center
    justify-content: space-between

    .Column-Remove
      margin: 0px 0px 0px 8px
      cursor: pointer
      opacity: 0.6
      justify-self: flex-end
      font-size: 22px

    .Column-Move
      display: flex
      cursor: pointer
      opacity: 0.6
      justify-self: flex-end
      font-size: 22px

    .Column-Color
      margin-left: 4px
      cursor: pointer
      opacity: 0.6
      font-size: 24px

  .Column-Settings
    display: flex
    padding: 0px 8px 2px
    align-items: center

    textarea
      font-size: 14px
      font-weight: 600
      text-transform: uppercase
      letter-spacing: 0.166666em

  .Column-NewPrompt
    padding: 8px 8px 8px 12px
    textarea
      background: rgba(255, 255, 255, 0.4)

  .Unused-Column
    border-right: 1px solid $lightLine
    min-width: 180px
    max-width: 360px
    flex: 1
    overflow: hidden
    display: flex
    flex-direction: column

    .MuiSvgIcon-root
      opacity: 0.4

    textarea
      opacity: 0.6

    h3
      font-weight: 600
      padding: 8px 12px 8px
      font-size: 15px

    .Unused-Prompts
      overflow-y: auto
      flex: 1

      .Prompt-Remove
        opacity: 0.6
        font-size: 20px
        line-height: 20px

      .Prompt
        display: flex
        padding: 2px 8px 0px 4px
        align-items: center

        .DragPrompt.MuiSvgIcon-root
          align-self: flex-start
          margin-top: 4px

.CanvasMenu
  .BoardControls
    display: flex
    margin-left: 12px
    align-items: center

    textarea
      border-radius: 4px
      background: rgba(255, 255, 255, 0.4)

    .RemoveBoard
      display: flex
      align-items: center
      margin-left: 4px
      cursor: pointer

.ColorPicker
  padding: 16px

.Counts
  margin-left: 4px
  border-radius: 10px
  align-self: center
  background: rgba(0, 0, 0, 0.1)
  min-width: 22px
  text-align: center
  font-size: 11px
  font-weight: 700
  line-height: 20px
  vertical-align: middle
  padding: 0px 6px
  letter-spacing: 0em

.CanvasWrapper.Dark
  .Unused-Column
    border-right: 1px solid $darkLine

  .Edit
    .Column
      border-right: 1px solid $darkLine

    .Counts
      background: rgba(255, 255, 255, 0.1)

  .Column-NewPrompt
    textarea
      background: rgba(0, 0, 0, 0.2)
