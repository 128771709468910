@import "./variables.sass"

.CanvasWrapper
  .Settings
    flex: 1
    margin-top: 0px
    border-top: 1px solid $lightLine
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: 16px
    font-weight: 500
    padding-bottom: 80px

    .Account, .Subscription
      min-width: 480px
      margin-bottom: 48px

      h2
        margin-bottom: 8px

      .Row
        display: flex
        align-items: center
        justify-content: space-between

.CanvasWrapper.Dark
  .Settings
    border-top: 1px solid $darkLine
