@import "../variables.sass"

.ShadowScrollbars
  & > div
    display: flex
    flex-direction: column

.TopShadow
  position: absolute
  top: 0
  left: 0
  right: 0

.BottomShadow
  position: absolute
  bottom: 0
  left: 0
  right: 0

@media (max-width: $singleColumnWidth)
  .ShadowScrollbars
    overflow: unset !important
    position: relative

    & > div
      position: relative !important
      overflow: unset !important

  .TopShadow, .BottomShadow
    display: none
