.App
  text-align: left

  &-logo
    height: 40vmin
    pointer-events: none

.App-header
  background-color: #282c34
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: calc(10px + 2vmin)
  // color: white

.Loading
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100vh
