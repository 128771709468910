@import "./variables.sass"

.Marketing
  background: #111
  display: flex
  flex-direction: column
  align-items: center
  font-family: $system-font-family
  overflow: hidden
  color: #aaa
  font-size: 18px
  font-weight: 600

  h1
    font-family: $font-family
    font-size: 96px
    margin-top: 200px
    margin-bottom: 12px
    font-weight: 600
    color: #ccc
    letter-spacing: 0.8em
    margin-right: -0.8em
    text-transform: uppercase

  h2
    font-size: 48px
    color: #bbb
    margin: 128px 0px 32px

  h3
    font-size: 36px
    color: #aaa
    text-align: center
    line-height: 1.2em
    margin: 96px 0px 36px

  .Logo
    margin-top: 96px
    width: 160px
    height: auto

  .Shape
    width: 160px

  .Section
    width: 100%
    max-width: 1280px
    display: flex
    flex-direction: column
    align-items: center
    position: relative

    *
      position: relative

    ul
      max-width: 640px
      text-align: left
      font-size: 17px
      font-weight: 600
      color: #888
      padding: 0

      li
        margin-bottom: 0.5em
        padding: 0
        list-style: none

        &.Negative
          svg
            color: #ff4e4e

        &.Positive
          svg
            color: #beff6b

        &.Feature
          svg
            color: #977cff

        svg
          font-size: 20px
          line-height: 20px
          vertical-align: middle
          margin-right: 8px
          margin-top: -4px

    &.Top
      flex-direction: row
      justify-content: space-between
      position: fixed
      z-index: 100
      border-radius: 6px
      backdrop-filter: blur(10px)
      padding: 12px

      div
        flex: 1
        display: flex
        justify-content: center

        &:first-of-type
          justify-content: flex-start

        &:last-of-type
          justify-content: flex-end

      .GetStarted
        margin-top: 0px

    &.Bottom
      margin: 240px auto 240px

    &.Header
      .Bg
        opacity: 0.05
        position: fixed
        top: 100px
        width: 1200px

  .Pricing, .Areas
    display: flex
    flex: 1
    width: 100%
    justify-content: space-between
    column-gap: 24px
    flex-wrap: wrap

    .Tier, .Area
      flex: 1
      display: flex
      flex-direction: column
      align-items: center
      min-width: 300px

      h4
        color: white
        font-size: 18px
        font-weight: 500
        text-align: center
        margin: 24px
        position: relative

        &.Highlight:before
          background: #8848ff
          content: ""
          display: block
          width: 100%
          height: 24px
          position: absolute
          top: 0
          transform: skew(0,-1deg)
          opacity: 0.4

      .Old
        text-decoration: line-through
        color: red

  a
    text-decoration: none
    color: #888
    background: #333
    font-weight: 600
    font-size: 16px
    border-radius: 4px
    padding: 10px 20px
    text-decoration: none
    transition: color 0.75s, background 0.75s

    &:hover
      transition: color 0.75s, background 0.75s
      color: black
      background: #ddd

    &.GoToApp
      align-self: flex-end
      margin-right: 24px
      background: none

      &:hover
        color: white

    &.GetStarted
      margin-top: 24px
      color: #333
      background: #bbb
      // background: -webkit-linear-gradient(#eee, #333)
      // -webkit-background-clip: text
      // -webkit-text-fill-color: transparent

      &:hover
        transition: color 0.75s, background 0.75s
        color: black
        background: #fff

    &.Discount
      margin-left: 24px

@media (max-width: $singleColumnWidth)
  .Marketing
    .Logo
      margin-top: 96px

    h1
      font-size: 32px
      margin-top: 24px
      margin-bottom: 32px

    h2
      margin-top: 64px
      margin-bottom: 12px

    .Pricing
      .Tier
        h4
          margin-bottom: 0px
          br
            line-height: 0px

    .Logo
      width: 120px

    a
      &.GoToApp
        margin-right: 0px

      &.Discount
        margin-left: 0px

    .Section
      padding: 0px 24px

      &.Top
        position: relative

      &.Bottom
        margin-top: 120px
