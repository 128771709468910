@import "./variables.sass"

.JournalWrapper
  height: 100vh
  display: flex
  flex-direction: column

  .CanvasMenu
    display: flex
    justify-content: space-between
    align-items: center
    padding: 4px 6px 4px 8px

    .CanvasTitle
      button
        margin-left: 0px

    h1
      font-size: 20px

    .logo
      display: none
      margin-left: 6px

  .CanvasControls
    display: flex
    // flex: 1
    justify-content: flex-end
    align-items: center

    .MuiIconButton-label
      font-size: 22px

.Journal
  display: flex
  overflow: hidden
  flex: 1
  margin-top: 0px
  border-top: 1px solid $lightLine

  .Columns
    display: flex
    flex-direction: column
    flex: 1
    overflow: auto
    margin: 0px

    .Empty
      display: flex
      flex: 1
      justify-content: center
      align-items: center
      font-size: 20px
      font-weight: 700
      opacity: 0.2

    .Section, .Section-Title
      display: flex
      flex-direction: column
      align-items: center
      margin-right: 16px

      .Section-Bg
        flex: 1
        display: flex
        align-items: flex-start
        max-width: 1600px
        width: 100%

      .Section-Header
        display: flex
        align-items: center
        justify-content: space-between
        margin-right: 24px
        font-size: 15px
        letter-spacing: 0.166666em
        margin-bottom: 8px
        text-transform: uppercase
        position: sticky
        top: 0
        transform: translate(0px, -1px)

        h2
          border-top: 1px solid $lightLine
          padding-top: 12px
          padding-left: 12px
          font-size: 14px
          width: 160px

          &.Section-Title-Month
            border: none
            font-size: 24px
            font-weight: 800
            width: auto

        .Section-Date-Day
          font-size: 20px
          font-weight: 500

        .Section-Date-Weekday
          font-family: garamond-premier-pro, sans-serif
          font-size: 11px
          font-weight: normal
          margin-bottom: 8px

        a
          opacity: 0.8

    .Section
      padding-bottom: 64px

    .Answers
      flex: 1
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-template-rows: repeat(auto-fill,auto-fill)
      grid-row-gap: 64px
      grid-column-gap: 48px

    .Answer
      display: flex
      flex-direction: column

      .Content
        max-height: 10em
        overflow: auto

      h3
        font-family: garamond-premier-pro, sans-serif
        font-size: 16px
        opacity: 0.6
        font-style: italic
        font-weight: 400
        margin-bottom: 8px

      .Content
        font-size: 16px
        font-weight: 500
        white-space: pre-wrap

    &.SingleColumn

      .Section
        display: flex
        align-items: center
        justify-content: center

      .Section-Bg, .Section-Title
        max-width: 720px
        flex: 1

      h1
        max-width: 640px
        align-self: center
        font-size: 20px
        margin-bottom: 20px

      .Answers
        display: flex
        flex-direction: column

  .Navigation
    display: flex
    flex-direction: column
    width: 20%
    min-width: 200px
    margin: 0px
    padding-top: 2px
    border-right: 1px solid $lightLine

    .Books
      text-transform: uppercase
      font-size: 14px
      font-weight: 600
      // letter-spacing: 0.16666em

    .Prompts
      margin-top: 4px
      border-top: 1px solid rgba(0, 0, 0, 0.1)
      overflow: auto

    a
      color: black
      display: block
      text-decoration: none
      opacity: 0.8
      font-weight: 500
      padding: 4px 8px
      margin: 4px
      border-radius: 4px
      transition: background 0.7s
      display: flex
      justify-content: space-between

      &:hover
        background: rgba(0, 0, 0, 0.05)
        transition: background 0.7s

      &.selected
        background: rgba(0, 0, 0, 0.1)

.Dark.JournalWrapper
  .Navigation
    border-right: 1px solid $darkLine

    a
      color: white

      &:hover
        background: rgba(255, 255, 255, 0.1)

      &.selected
        background: rgba(255, 255, 255, 0.15)

    .Prompts
      border-top: 1px solid $darkLine

  .Section
    .Section-Header
      h2
        border-top: 1px solid $darkLine

  .Answers
    .Answer
      // background: rgba(0, 0, 0, 0.2)

@media (max-width: $doubleColumnWidth)
  .Journal
    .Columns
      .Section, .Section-Title
        display: flex
        flex-direction: column
        align-items: center

        .Section-Bg
          max-width: 1280px

      .Answers
        grid-template-columns: repeat(2, 1fr)

@media (max-width: $singleColumnWidth + 640px)
  .Journal
    .Columns
      .Section, .Section-Title
        display: flex
        flex-direction: column
        align-items: center

        .Section-Bg
          max-width: 720px

      .Answers
        grid-template-columns: repeat(1, 1fr)

        .Answer
          .Content
            max-height: unset
