$darkBg: #232428
$darkLine: #2e3036
// $lightBg: #F8F1E1
// $lightLine: #D1BDAD
$lightBg: #f8f8fb
$lightLine: #e4e4f2

$doubleColumnWidth: 1800px
$singleColumnWidth: 768px

$font-family: proxima-nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
$system-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
